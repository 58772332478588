<template>
  <div class="productContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">机构评价</div>
    <div class="card">
        <div class="catalog">
            <p>服务满意程度</p>
            <div class="select">
                <select v-model="serviceSatisLevel">
                    <option value="0">非常满意</option>
                    <option value="1">满意</option>
                    <option value="2">一般</option>
                    <option value="3">不满意</option>
                    <option value="4">非常不满意</option>
                </select>
            </div>           
        </div>
        <div class="catalog">
            <p style="height: 20vw">意见建议<span class="key">*</span></p>
            <textarea v-model="advice"></textarea>
        </div>
        <div style="text-align: center;padding: 4vw 0;">
            <div @click="submit()" class="edit">提交</div>
        </div>      
    </div>
    
    
  </div>
</template>
<script>
import { Toast } from 'vant';
import { ref,reactive,toRefs, toRaw } from 'vue';
import { useRouter } from 'vue-router';
import { apiCommentSave } from '../../api/axios';
export default {
   setup() {
    let router = useRouter();
    // console.log(router.currentRoute.value.params.approvalId,999);
    let rId = router.currentRoute.value.params.requireId;
    let code = router.currentRoute.value.params.bankCode;
    const state = reactive({
        serviceSatisLevel: '0',
        advice: ''
    });
    const submit = () => {
        if(state.advice.length > 0) {
            let obj = toRaw(state);
            obj.requireId = rId;
            obj.bankCode = code;
            console.log(obj)
            apiCommentSave(obj).then(res => {             
                if(res.code == '1'){                
                    Toast.success('提交成功！');
                    router.go(-1);
                }
                else {
                    Toast(res.msg);
                }
            });
        }else {
            Toast('请先填写意见建议');
        }
    }
    return { 
        ...toRefs(state),
        submit
    };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },

  }
}
</script>
<style lang="less" scoped>
.productContent {
    padding-top: 12.5vw;
    .card {
        padding: 2vw 4vw;
        .catalog {
            display: flex;
            align-items: center;
            p {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }

            select,input,textarea {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 55vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }
            textarea {
                height: 20vw;
                resize: none;
            }

        }
        .edit {
            display: inline-block;
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4.5vw;
            padding: 1vw 6vw;
            border-radius: 5vw;
            border: 0;
        }
        
    }
    
    
}
</style>
